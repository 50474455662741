<template>
  <div class="up-fornt-lead" :class="{ 'error': validation.hasErrors}">
    <div class="header">
      <lazy-image :src="image" :alt="headline" :class="{ 'error': isImageInvalid }" :data-validation="isImageInvalid"/>
      <img v-show="logo" class="logo" :src="logo" :class="{ 'error': isLogoInvalid }" :data-validation="isLogoInvalid" alt="Logo">
    </div>
    <div class="body">
      <h3 v-show="headline" :class="{ 'error': validation.headline }" :data-validation="validation.headline">{{ headline }}</h3>
      <p v-show="offer" :class="{ 'error': validation.offer }" :data-validation="validation.offer">{{ offer }}</p>
      <p v-show="optIn" class="opt-in" :data-validation="validation.optIn" :class="{ 'error': validation.optIn }">{{ optIn }}</p>
    </div>
    <div v-show="cta" class="footer">
      <button class="cta" :class="{ 'error': validation.cta || validation.urlIssue }" :data-validation="validation.cta || validation.urlIssue">{{ cta }}</button>
    </div>
  </div>
</template>
<script>
import LazyImage from '@/components/commons/LazyImage'
import { mapActions } from 'vuex'

export default {
  name: 'UpFrontLead',
  props: {
    image: {
      type: String,
      required: true,
      default: null,
    },
    logo: {
      type: String,
      required: true,
      default: null,
    },
    headline: {
      type: String,
      required: true,
      default: null,
    },
    offer: {
      type: String,
      required: true,
      default: null,
    },
    optIn: {
      type: String,
      required: true,
      default: null,
    },
    cta: {
      type: String,
      required: true,
      default: null,
    },
    validation: {
      type: Object,
      required: false,
      default: () => ({}),
    }
  },
  components: {
    LazyImage
  },
  data: () => ({
    isImageInvalid: false,
    isLogoInvalid: false
  }),
  methods: {
    ...mapActions({
      getImageSize: 'leads/getImageSize'
    })
  },
  async beforeMount() {
    try {
      const { width, height } = await this.getImageSize(this.image)
      if (width > 1288 || height > 512) {
        this.isImageInvalid = 'Max Image Size (1288x512)'
      }
    } catch(err) {
      this.isImageInvalid = 'Image Not Found'
    }
    try {
      const { width, height } = await this.getImageSize(this.logo)
      if ( (width < 400 && width > 560) || height > 156) {
        this.isLogoInvalid = 'Max Logo Size (400,560x156)'
      }
    } catch(err) {
      this.isLogoInvalid = 'Image Not Found'
    }
  }
}
</script>
<style lang="scss" scoped>
.up-fornt-lead {
  width: 332px;
  border-radius: 4px;
  box-shadow: 0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20);
  // overflow: hidden;
  box-sizing: border-box;
  & .header {
    height: 128px;
    position: relative;
    & .lazy-image {
      height: 128px;
      &.error {
        @include lead-error-item;
      }
    }
    & img {
      &.error {
        @include lead-error-item;
      }
      &.logo {
        width: 100px;
        height: 37px;
        object-fit: cover;
        position: absolute;
        bottom: -12px;
        left: 18px;
        border-radius: 4px;
        &.error {
          @include lead-error-item;
        }
      }
    }

  }
  & .body {
    padding: 0 18px;
    & h3 {
      font-family: $regular;
      font-size: 18px;
      line-height: 1.4;
      color: $dark-grey-text;
      margin-top: 32px;
      margin-bottom: 0;
      &.error {
        @include lead-error-item;
      }
    }
    & p {
      color: $orangey-red;
      margin-top: 5px;
      margin-bottom: 0;
      font-size: 18px;
      &.error {
        @include lead-error-item;
      }
      &.opt-in {
        margin-top: 37px;
        color: $dark-grey-text;
        font-size: 10px;
        &.error {
      @include lead-error-item;
    }
      }
    }
  }
  & .footer {
    padding: 18px;
    & button.cta {
      width: 100%;
      display: block;
      cursor: pointer;
      border: none;
      outline: none;
      font-family: $headline;
      font-size: 20px;
      color: $white;
      background-color: $orangey-red;
      border-radius: 4px;
      padding: 13px 15px;
      box-shadow: 0 2px 0 0 rgba(0,0,0,0.25);

      &:hover {
        color: rgba($white, .8);
      }

      &.error {
        @include lead-error-item;
      }
    }
  }

  &.error {
    @include lead-error;
  }
}
</style>
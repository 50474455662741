<template>
  <div class="leads-list">
    <div v-for="(lead, $index) in leads" :key="`lead_item_${$index}`" class="lead-item">
      <div class="leads-details">
        <dl>
          <dt>ID: </dt>
          <dd>{{ lead.lead_id }}</dd>
          <dt>Type: </dt>
          <dd>{{ lead.lead_type }}</dd>
        </dl>
      </div>
      <div class="leads-preview">
        <div>
          <h3>Up Front Lead Preview</h3>
          <up-front-lead
          :image="lead.offer_details.uf_offer_image_url"
          :logo="lead.offer_details.uf_logo_url"
          :headline="lead.offer_details.uf_headline"
          :offer="lead.offer_details.uf_offer"
          :opt-in="lead.offer_details.uf_opt_in"
          :cta="lead.offer_details.uf_cta"
          :validation="lead.validation.uf" />
        </div>
        <div>
          <h3>Dashboard Lead Preview</h3>
          <dashboard-lead
          :image="lead.offer_details.db_image"
          :logo="lead.offer_details.db_logo"
          :product="lead.offer_details.db_product"
          :info="lead.offer_details.db_info"
          :cta="lead.offer_details.db_cta"
          :validation="lead.validation.db" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import UpFrontLead from '@/components/leads/UpFrontLead'
import DashboardLead from '@/components/leads/DashboardLead'

export default {
  name: 'LeadList',
  components: {
    DashboardLead,
    UpFrontLead
  },
  computed: {
    ...mapGetters({
      leads: 'leads/leads'
    })
  }
}
</script>
<style lang="scss" scoped>
.leads-list {
  width: 80%;
  margin: auto;
  padding: 2em 0;

  & .lead-item {
    padding: 2em 0;
    &:not(:first-child) {
      border-top: 2px solid $dark-indigo;
      border-radius: 2px;
    }

    & .leads-preview {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      & > div > h3 {
        text-align: center;
        color: $dark-indigo;
      }
      & > div:first-child {
        margin-right: 4em;
        position: relative;
        &::after {
          content: ' ';
          position: absolute;
          width: 2px;
          height: 100%;
          right: -2em;
          top: 0;
          border-radius: 4px;
          background-color: $dark-indigo;
        }
      }
    }

  }
}
</style>
<template>
  <div>
    <lead-list />
  </div>
</template>
<script>
import LeadList from '@/components/leads/LeadList'

export default {
    name: 'ListAllLeads',
    components: {
        LeadList
    }
}
</script>
